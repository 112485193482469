export {getObject, setObjectClass}

function getObject(data, $btn = undefined){
//	console.log('getObject data: ', data, ' $btn: ', $btn);
	var $o = null;
	if(data !== undefined){
		if(data.parent !== undefined){ // zamykające, np. data-hide='{"parent":".container-row","object":"form"}'
			var $parent = $btn.closest(data.parent);
//			console.log('$parent', $parent);
			if(!$parent.length){
				return null;
			}
			$o = data.object !== undefined ? $parent.find(data.object) : $parent;
		}else{ // eg. data-hide="#formAdmin" - dla jednoznacznych elementów
			$o = data !== 'body' ? $('body').find(data) : $('body');
		}
	}
	if($o !== null && !$o.length){
		$o = null;
	}
	console.log('getObject', $o);
	return $o;
}

function setObjectClass($o, type){
	if($o !== undefined  && $o.length){
//		$.each($o, function(i, el){
			switch(type){
				case 'hide': if(!$o.hasClass('d-none'))	$o.addClass('d-none');	break;
				case 'show': 
				case'display': if($o.hasClass('d-none')) $o.removeClass('d-none'); break;
				case 'inactive': if($o.hasClass('active')) $o.removeClass('active'); break;
				case 'active': if(!$o.hasClass('active')) $o.addClass('active'); break;
			}
//		});
	}
}



