import '../css/modules/_cart.scss';
import {createCookie, checkCookie, readCookie, searchToCookieName} from './lib/cookie';
import {getUrlParam, getServerUrl, getSiteUrl, getPathnameElements} from './lib/url';
import {getData, submitData, dataFromForm, dataFromForm2, emailTest, liveLoadData} from './lib/livedata';
import {scrollTo, formatBytes} from './lib/helpers';
import {beforeAlert, showMessage} from './lib/message';
import {trans, t, dict} from './lib/dictionary';
import {formCheck} from './lib/test';
import {getObject, setObjectClass} from './lib/object';
import {plusSlides, currentSlide, showSlides} from './lib/lightbox';
import {runEditor} from './lib/editor';

$(document).ready(function () {
	
	console.log('anywhere');
	var $body = $('body');

    // przypisanie data-pageWidth do body dla skryptów tworzących dynamiczne dane
    var width = $("body > .width").map(function() {
        if($(this).is(":visible")){
            return $(this).data('width');
        }
    }).get();
    $('body').data('width', width[0]);
	createCookie('bootstrap_width', width[0], 30);
	
	$body.on("change", "input[type=email]", function() {
		console.log($(this).val());
		emailTest($(this));
	});
	
	// kopiowanie pojedyncze
	$body.on('click', '.data-copy', function(){
		// {"parent":"div", "object":"textarea", "where":"append", "what":"text"}
		var $this = $(this),
			$data = $this.data('copy'),
			$to = $data !== undefined && $data.parent !== undefined && $data.object !== undefined ? getObject($data, $this) : null,
			where = $data.where !== undefined ? $data.where : 'append', // lub 'prepend'
			what = $data.what !== undefined ? $data.what : 'text', // lub 'html'
			content = null; 
		switch(what){
			case 'text' : content = $this.text(); break;
			case 'html' : content = $this.html(); break;
		}
		console.log('where: ' + where + ', what: ' + what + ', content: ' + content + ', $to: ', $to);
		if($to && content){
			switch (where) {
				case 'prepend' : $to.prepend("\n"); content = content + ' '; $to.prepend(content); $to.focus(); break;
				case 'append'  : $to.append("\n"); $to.append(content); $to.append(' '); $to.focus(); break;
			}
		}
	});
	
	// kopiowanie w pętli do elementów z wybranergo obiektu
	$body.on('click', '.data-copy-data', function(){
		// [{ "value": "{{ m.id }}", "fill": {"parent":".card-text", "object": "input[name=id]"}, "typeFill": "val" },...]
		var $this = $(this),
			$data = $this.data('copy'),
			clear= $this.data('clear');
		$this.parent().find('.active').removeClass('active');
		$this.addClass('active');
		console.log('data', $data);
		if(!$.isArray($data)) return false;
		for (let i = 0; i < $data.length; ++i) {
			pasteData($data[i], $this);
		}
		if(clear !== undefined){
			var $clear = getObject(clear, $this);
			if($clear !== undefined){
				$clear.val('');
			}
		}
	});
	
	$body.on('change', '.nip-find', function(){
		var $this = $(this),
			$data = $this.data(),
			$fill = getObject($data.fill, $this),
			$form = getObject($data.form, $this);
		console.log($data);
		if($fill !== undefined && $form !== undefined && $data.action !== undefined && $data.action.length){
			var data = dataFromForm($form);
			console.log('submitData', data);
			submitData(data, $data.action, $fill, 'html', null, null, $fill);
		}
	});
	
	// paginacja - zmiana strony
	$body.on('click', '.pagination .page-link', function(e){ //#liveDataLoad .pagination .page-link
		e.preventDefault();
		var $searchForm = $body.find('#searchForm'),
			$btn = $(this),
			$fill = $body.find('#liveDataLoad'),
			$subFill = $fill !== undefined ? $fill.find('#pageDataLoad') : undefined;
		console.log('$fill',$fill);
		console.log('$subFill',$subFill);
		if($fill === undefined || !$fill.length){
			alert('Blok "#liveDataLoad" jest konieczny, aby załadowanć do niego ' + $btn.data('page') + ' stronę!');
			return false;
		}
		if($subFill !== undefined && $subFill.length){
			$fill = $subFill;
		}
		console.log('$fill after',$fill);
		if($searchForm !== undefined){
			let $inputPage = $searchForm.find('input[name=page]');
			if($inputPage !== undefined){
				$inputPage.val($btn.data('page'));
			}
			let $inputCookie = $searchForm.find('input[name=cookie]');
			if($inputCookie !== undefined){
				searchToCookieName($searchForm, $inputCookie.val());
			}
			let action = $searchForm.prop('action'),
				searchData = dataFromForm($searchForm);
			if(action === undefined || !action.length){
				alert('Formularz wyszukiwania nie posiada określenia "action"!');
				return false;
			}
			submitData(searchData, action + '/' + $btn.data('page'), $fill, 'html');
		}else{
			let action = $fill.data('action');
			if(action === undefined || !action.length){
				alert('#liveDataLoad nie posiada określenia "data-action"!');
				return false;
			}
			getData(action + '/' + $btn.data('page'), $fill, 'html');
		}
		scrollTo($fill);
	});
	
//	$body.on('click', '#searchForm .search-btn', function () {
//		if($('body').hasClass('body-adoptions') || $('body').hasClass('body-adoption')){
//			return false;
//		}
//		var $btn = $(this),
//			$form = $btn.closest('#searchForm'),
//			cookieName = $form.find('input[name=cookie]').val(),
//			$pageInput = $form.find('input[name=page]'),
//			$fill = $body.find('#liveDataLoad'),
//			$pFill = $fill.find('#pageDataLoad'),
//			sFill = $form.data('fill'),
//			$isAdoption = $fill.find('.search-adoption-table');
////		console.log('$fill', $fill);
////		console.log('$fill.find(#pageDataLoad)', $fill.find('#pageDataLoad'));
//		if($fill === undefined || ($fill.length === 0) || ($isAdoption !== undefined && $isAdoption.lenght)) return false;
//		if($pFill.length) $fill = $fill.find('#pageDataLoad');
//		if(sFill !== undefined && $body.find(sFill).length) $fill = $body.find(sFill); 
//		if($pageInput !== undefined) $pageInput.val(1);
//		if(cookieName !== undefined) searchToCookieName($form, cookieName);
////		console.log('$fill', $fill);
//		liveLoadData($form, $fill);
//	});
//	$body.on('click', '#searchForm .clear-fields', function () {
//		if($('body').hasClass('body-adoptions') || $('body').hasClass('body-adoption')){
//			return false;
//		}
//		var $btn = $(this),
//			$form = $btn.closest('#searchForm'),
//			cookieName = $form.find('input[name=cookie]').val(),
//			$pageInput = $form.find('input[name=page]'),
//			$fill = $body.find('#liveDataLoad'),
//			$pFill = $fill.find('#pageDataLoad'),
//			sFill = $form.data('fill');
//		console.log('cookieName', cookieName);
//		searchToCookieName($form, cookieName);
//		if($fill === undefined) return false;
//		if($pFill.length) $fill = $fill.find('#pageDataLoad');
//		if(sFill !== undefined && $body.find(sFill).length) $fill = $body.find(sFill);
//		$btn.closest('.input-group').find('input').val('');
//		if($pageInput !== undefined) $pageInput.val(1);
//		liveLoadData($form, $fill);
//	});
	
	$body.on('click', '.input-group .input-group-append .clear', function(){
		var $btn = $(this),
			$inp = $btn.closest('.input-group').find('input');
		$inp.val('');
		$btn.blur();
		$inp.focus();
	});

	//Scroll
	$body.on('click', '.scrollable', function() {
		let toggleData = $(this).data('toggle');
		if($.isArray(toggleData)) {
			for (const tdItem of toggleData) {
				if (typeof tdItem === 'object') {
					if (tdItem.hasOwnProperty('scroll')) {
						console.log('tdItem[scroll]', tdItem['scroll']);
						scrollTo(tdItem['scroll']);
					}
				}
			}
		} else {
			console.log('toggleData',toggleData);
			console.log('toggleData.hasOwnProperty(scroll)',toggleData.hasOwnProperty('scroll'));
			if (toggleData.hasOwnProperty('scroll')) {
				scrollTo(toggleData['scroll']);
			}
		}
	});

	// wypełnienie koszyka na start strony
//	if(checkCookie('cart')){
//		getData('/cart', $('#cart'));
//	}
	// dodanie zbiórki do koszyka lub usunięcie z koszyka
	$('main').on('click', '.heart-cart', function(){
		var $btn = $(this),
			action = $btn.data('action'),
			$fill = $body.find($btn.data('fill'));
		getData(action, $fill);
		setTimeout(function(){
				var $searchForm = $('main').find('#searchForm');
				if($searchForm !== undefined){
					var	$fill = $('main').find('#liveDataLoad'),
						action = $searchForm.prop('action') + '/' + $searchForm.find('input[name=page]').val(),
						data = dataFromForm($searchForm);
					if($fill !== undefined){
						submitData(data, action, $fill);
					}
				}
		}, 900);
	});
	
	// kliknięcie na logo lub ikonę home
	
	var $header = $('#site-header'),
		$linkLogo = $header.find('.navbar-brand'),
		$linkHomeIco = $header.find('.nav-item.active .nav-link-home-ico');
		
	$linkLogo.on('click', function(e){
		e.preventDefault();
		toHomeGo($(this));
	});
	
	$linkHomeIco.on('click', function(e){
		e.preventDefault();
		toHomeGo($(this));
	});
	
	$body.on('click', '.close', function(){
		var alert = $(this).closest('.alert');
		if(alert !== undefined){
			alert.fadeOut(700);
			alert.remove();
		}
	});
	
	// informacja o cookies
	
	var $cookieBar = $('#cookie-info');
	
	if(!checkCookie('cookies_notify')){
		$cookieBar.show();
	}
	
	$cookieBar.on('click', '.cookie-btn', function(){
		$cookieBar.slideUp("slow");
		createCookie('cookies_notify', true, 30);
	});

	// zmiana języka
	$('#navbarTopMenu').on('click', '.set-lang', function(){
		createCookie('_locale', $(this).data('lang'), 360);
		createCookie('country_id', $(this).data('country'), 360);
		createCookie('user_locale', $(this).data('lang'), 360);
		window.location.reload(true);
	});

	// aktywacja / deaktywacja przycisku menu
	
	$('.nav-tabs .nav-link').on('click', function(){
		$(this).blur();
		$(this).parents('.nav-tabs').find('.nav-item.active').removeClass('active');
		$(this).parent().addClass('active');
	});
	
	// activation all tooltips
	$('[data-toggle="tooltip"]').tooltip();
	
	// toggle collapse with caret rotate
	$body.on('click', '.btn-caret', function(){
		var icon = $(this).find('.fas');
		if(icon.hasClass('fa-caret-right')){
			icon.removeClass('fa-caret-right'); 
			icon.addClass('fa-caret-down');
		}else{ 
			icon.removeClass('fa-caret-down'); 
			icon.addClass('fa-caret-right');
		}
		$(this).blur();
	});
	
	// toggle collapse
	$('.panel-group').on('hidden.bs.collapse', toggleIcon);
	$('.panel-group').on('shown.bs.collapse', toggleIcon);
	var sizeDefault = $('.panel-group').data('defaultShowSize');
	if( sizeDefault !== undefined){
		toggleDisplayPanel($('.panel-group'), sizeDefault);
	}
	
	// Nazwa załadowanego pliku w przypadku pola custom (upload)
	$body.on("change", 'input[type="file"]', function() {
		var fileName = $(this).val().split("\\").pop(),
			$label = $(this).siblings('label');
//		console.log('filename', fileName);
		$label.addClass("selected").html(fileName);
	});
	
	// test istnienia wartości pola
	$body.on('change', '.check-is-value', function(){
		var $field = $(this),
			value = $field.val(),
			action = $field.data('action'),
			method = action !== undefined ? $field.data('actionType') : undefined,
			required = $field.prop('required');
		if(required !== undefined && required){
			if(value.length > 1){
				if($field.hasClass('error')){
					$field.removeClass('error');
				}
			}else{
				$field.addClass('error');
			}
		}
		if(method !== undefined){
			switch(method){
				case 'get' :
					action = action + '/' + value;
					getData(action, $field, 'error', null, $field);
					break;
				case 'post' :
					submitData(action, $field, 'error', null, $field);
			}

		}
	});

	// dimiss prywatne - chowa obiekt nadrzędny przycisku .close,
	$body.on('click', '.close', function(){
		var $parent = $(this).parent();
		if($parent.hasClass('alert') || $parent.hasClass('message')){
			// komunikat niszczymy
			$parent.fadeOut(400).remove();
		}else{
			// tylko zamykamy to coś
			$parent.fadeOut(400);
		}
	});

	/////// Edycja jednego pola input, początkowo ukrytego (np. w tabeli) ///////

	// pokazanie pola edycji z buttonem, schowanie wyświetlanych danych
	$body.on('click', '.edit-field', function(){
		var $btn = $(this),
			$edited = $btn.siblings('.edited'),
			$displayed = $btn.siblings('.displayed');
		$displayed.fadeOut(400);
		$btn.fadeOut(400);
		$edited.removeClass('d-none');
	});
	// zapis pola, podmiana danych, wyświetlenie komunikatu
	$body.on('click', '.input-group.edited .fa.fa-check', function(){
		var $btn = $(this),
			action = $btn.data('action'),
			$input = $btn.closest('.input-group-append').siblings('input'),
			value = $input.val(),
			$inputGroup = $btn.closest('.input-group'),
			$displayed = $inputGroup.siblings('.displayed'),
			$btnEdit = $inputGroup.siblings('.edit-field');
		console.log('action', action, value);
		submitData(value, action, $displayed, 'text', null, null, $displayed);
		$btn.closest('.input-group').addClass('d-none');
		$displayed.fadeIn(400);
		$btnEdit.fadeIn(400);
	});
	// anulowanie zapisu pola
	$body.on('click', '.input-group.edited .fa.fa-times', function(){
		var $btn = $(this),
			$inputGroup = $btn.closest('.input-group'),
			$displayed = $inputGroup.siblings();
		$inputGroup.addClass('d-none');
		$displayed.fadeIn(400);
	});
	/////////////////////////////////////////

	// filter w liście ul.dropdown-menu z inputem 
	$body.on("keyup", ".search-list", function() {
		searchList($(this));
	});

	// testowanie wielkości pliku w pikselach przy uploadzie
	$body.on('change', '.file-validator', function() {
		fileValidate($(this));
	});
	
	// action form - button - click
	$body.on('click', '.form-action.btn', function () {
		sendFormAction($(this));
	});
	// action form - input - change value
	$body.on('change', '.form-action.inp', function () {
		sendFormAction($(this));
	});

	$body.on('keypress', '.form-action.inp', function (e) {
		if (e.which === 13)
			return false;
	});
	$body.on('keydown', '.form-action.inp', function (e) {
		if (e.which === 13) {
			sendFormAction($(this));
			return false;
		}
	});

	// action form - input - key enter
	$body.on('keyup', '.form-action.inp', function (e) {
		e.preventDefault();

		if (e.which === 13) {
			sendFormAction($(this));
		}
	});
	
	// pole z klasą integer może zawierać tylko liczbę całkowitą
	$body.on('keyup', '.integer', function(e){
		var s = this.value,
			f = parseFloat(s),
			r = Math.round(f);
		if(isNaN(r)){
			this.value = s.replace(/\D/g, '');
			showMessage(trans('only_number'), 'danger', $(this));
		}else if(f !== r){
			this.value = r;
			showMessage(trans('only_integer', [{search: '%val%', replace: f}]), 'danger', $(this));
		}
	});

	// lightbox
	var ibox = $('main').find('.default-photo');
	// otwarcie modala na właściwym obrazku
	ibox.on('click', '.open-modal', function () {
		var $modalGallery = $(this).data('gallery') !== undefined ? ibox.find($(this).data('gallery')) : $('#modal-gallery');
		$modalGallery.show(400);
		currentSlide($(this).data('slide'), $modalGallery);
	});
	// na główne kliknięta miniaturka w modalu
	ibox.on('click', '.slide-thumb', function () {
		var $modalGallery = $(this).closest('.modal-gallery');
		currentSlide($(this).data('slide'), $modalGallery);
	});
	// na główne kolejny obrazek
	ibox.on('click', '.prev', function () {
		var $modalGallery = $(this).closest('.modal-gallery');
		plusSlides(-1, $modalGallery);
	});
	// na główne poprzedni obrazek
	ibox.on('click', '.next', function () {
		var $modalGallery = $(this).closest('.modal-gallery');
		plusSlides(1, $modalGallery);
	});
	// zamykanie modala z galerią
	ibox.on('click', '.close', function () {
		var $modalGallery = $(this).closest('.modal-gallery');
		$modalGallery.hide(400);
	});
});

function sendFormAction($btn){
	var $fill = $btn.data('fill') !== undefined ? getObject($btn.data('fill'), $btn) : null,
		typeFill = $fill && $btn.data('typeFill') !== undefined ? $btn.data('typeFill') : 'html',
		messageBefore = $btn.data('messageBefore') === undefined ? null : getObject($btn.data('messageBefore'),$btn),
		actionType = $btn.data('actionType') === undefined ? 'post' : $btn.data('actionType'),
		form = $btn.data('form') !== undefined ? $btn.data('form') : null,
		$form = form ? (actionType === 'file' ? getObject(form, $btn)[0] : getObject(form, $btn)) : $btn.closest('form'),
		action = $btn.data('action') !== undefined ? $btn.data('action') : ($form !== undefined && $form.prop('action') !== undefined ? $form.prop('action') : undefined),
		$email = $form !== undefined ? $form.find('input[type=email]') : undefined;
	$btn.blur();
	if($email !== undefined && $email.length && !emailTest($email)){
		return false;
	}
	if (action !== undefined && $form !== undefined && formCheck($form)) {
		switch(actionType){
			case 'file' : 
				submitDataFile($form, action, $fill, typeFill, messageBefore); 
				return true;
			case 'post' : 
				submitData(dataFromForm($form), action, $fill, typeFill, null, null, messageBefore); 
				return true;
			default     : 
				$form.submit(); 
				return false;
		}
	}		
}

function toggleDisplayPanel($panelGroup, sizeDefault){
	var $el = $panelGroup.find($panelGroup.data('defaultShowElement')),
		$btn = $el.closest('.panel').find('.panel-heading .fas'),
		aSize = $('body').data('width');
//	console.log('$el: ' + $el, 'aSize: ' + aSize, '$panelGroup: ' + $panelGroup, 'sizeDefault: ' + sizeDefault);
	if($el !== undefined && $el.length && aSize !== undefined && aSize.length){
		switch(sizeDefault){
			case 'sm': 
				if(aSize !== 'xs') {
					$el.addClass('show'); 
					$btn.removeClass('fa-plus');
					$btn.addClass('fa-minus');
				} 
				break;
			case 'ml': 
				if(aSize !== 'xs' && aSize !== 'sm') {
					$el.addClass('show');
					$btn.removeClass('fa-plus');
					$btn.addClass('fa-minus');
				} 
			break;
			case 'lg': 
				if(aSize === 'lg' || aSize === 'xl') {
					$el.addClass('show');
					$btn.removeClass('fa-plus');
					$btn.addClass('fa-minus');
				} 
				break;
			case 'xl': 
				if(aSize === 'xl') {
					$el.addClass('show');
					$btn.removeClass('fa-plus');
					$btn.addClass('fa-minus');
				} 
				break;
			default: 
				$el.addClass('show');
				$btn.removeClass('fa-plus');
				$btn.addClass('fa-minus');
		}
	}
}

function toggleIcon(e) {
    $(e.target).prev('.panel-heading').find(".fas").toggleClass('fa-plus fa-minus');
}

/**
 * 
 * @param {object} $btn
 * @returns {undefined}
 */
function toHomeGo($btn){
	createCookie('video', 1, 0.02);
	window.location.assign($btn.prop('href'));
}

// kopiowanie: musi być obiekt z value, fill oraz typeFill + drugi obiekt DOOM - button dla getObject
function pasteData($data, $btn){
	// Object { value: "1624", fill: Object { parent: ".card-text", object: "input[name=id]" }, typeFill: "val" }
	var $fill = getObject($data.fill, $btn),
		typeFill = $data.typeFill,
		value = $data.value.trim();
	if($fill === undefined || typeFill === undefined || value === undefined) return false;
	switch(typeFill){
		case 'val': 
		case 'value':
			$fill.val(value); break;
		case 'txt':
		case 'text':
		case 'number':
			$fill.text(value); break;
		case 'html':
			$fill.html(value); break;
		case 'after':
			$fill.after(value); break;
		case 'before':
			$fill.before(value); break;
		case 'prepend':
			$fill.prepend(value); break;
		case 'append':
			$fill.append(value); break;
	}
	if($fill.prop('type') === 'email' && value.length && !emailTest($fill)){
		return false;
	}
	return true;
}

function searchList($inp){
	var value = $inp.val().toLowerCase().trim(), 
		action = $inp.data('action') + '/' + value,
		$fill = getObject($inp.data('fill'), $inp);
//	console.log($fill);
	if($fill === undefined || action === undefined) return null;
	getData(action, $fill);
}